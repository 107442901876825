import React from "react";

import styles from "./resources.module.scss";

export const Resources = (props) => {
  let dark = props.theme;
  return (
    <div
      className={
        dark ? `${styles["home"]} ${styles["dark"]}` : `${styles["home"]}`
      }
    >
      <iframe
        id="dashboard-iframe"
        frameborder="0"
        allowfullscreen="allowfullscreen"
        width="100%"
        height="2000"
        src="chart/dashboard/show/71c1f06ce18447e7d3f7/"
      ></iframe>
    </div>
  );
};
