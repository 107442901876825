import React from "react";
import "./Ques.scss";

import styles from "../Home/home.module.scss";

export const Ques = (props) => {
  let dark = props.theme;
  return (
    <div
      className={
        dark ? `${styles["home"]} ${styles["dark"]}` : `${styles["home"]}`
      }
    >
      <iframe
        id="dashboard-iframe"
        frameborder="0"
        allowfullscreen="allowfullscreen"
        width="100%"
        height="800"
        src="block-generate.html"
      ></iframe>
    </div>
  );
};
export default Ques;
